<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="班级名称："
          prop="class_name"
          :rules="[{ required: true, message: '请输入班级名称' }]"
        >
          <el-input
            placeholder="请输入班级名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.class_name"
          ></el-input>
        </el-form-item>

        <el-form-item
          class="form-inp"
          label="学院/部门："
          prop="department_id"
          :rules="[{ required: true, message: '请选择部门' }]"
        >
          <Bepartment ref="Bepartment" v-model="formLabelAlign.department_id" />
        </el-form-item>

        <!-- <el-form-item
          label="班主任："
          prop="master_id"
          :rules="[{ required: true, message: '请选择班主任' }]"
        >
          <el-select
            v-model="formLabelAlign.master_id"
            placeholder="请选择班主任："
          >
            <el-option
              v-for="item in teacher_Data"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="班主任："
          prop="master_id"
          :rules="[{ required: true, message: '请选择班主任' }]">
          <el-select
              v-model="formLabelAlign.master_id"
              filterable
              remote
              clearable
              placeholder="请输入姓名查询"
              :remote-method="(query)=>getUserAndDept(query,{type:1})"
              :loading="loading"
              class="form-select"
              >
              <el-option
                v-for="it in teacherData"
                :key="it.id"
                :label="it.nickname"
                :value="it.ding_userid">
                <span style="float: left">{{ it.nickname }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ it.department }}</span>
              </el-option>
            </el-select>
        </el-form-item>

        <el-form-item
          label="调解员："
          prop="mediator"
          :rules="[{ required: true, message: '请选择调解员' }]"
        >
        <el-select
              v-model="formLabelAlign.mediator"
              filterable
              remote
              clearable
              placeholder="请输入姓名查询"
              :remote-method="(query)=>getUserAndDept(query,{type:2})"
              :loading="loading"
              class="form-select"
              >
              <el-option
                v-for="it in mediatorData"
                :key="it.id"
                :label="it.nickname"
                :value="it.ding_userid">
                <span style="float: left">{{ it.nickname }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ it.department }}</span>
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item
          label="安全辅导员："
          prop="counselor"
          :rules="[{ required: true, message: '请选择安全辅导员' }]"
        >
        <el-select
          v-model="formLabelAlign.counselor"
          filterable
          remote
          clearable
          placeholder="请输入姓名查询"
          :remote-method="(query)=>getUserAndDept(query,{type:3})"
          :loading="loading"
          class="form-select"
          >
          <el-option
            v-for="it in counselorData"
            :key="it.id"
            :label="it.nickname"
            :value="it.ding_userid">
            <span style="float: left">{{ it.nickname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ it.department }}</span>
          </el-option>
        </el-select>
        </el-form-item>
        
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  banjisaveInfo,
  usergetUserByDepart,
  banjisave,
  getUserAndDepartment
} from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
export default {
  name: "class_redact",
  components: {
    Bepartment,
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      loading:false,
      formLabelAlign: {
        class_name: "",
        department_id: "",
        sort_name: "",
        master_id: "1",
        begin_time: "",
        end_time: "",
        is_enable: "",
        mediator:"",
        counselor:"",
      },
      serial_Data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      timeData: [],
      options: [],
      teacherData: [],
      counselorData:[],
      mediatorData:[],
      TSmaster_id: "",
    };
  },
  methods: {
    way_show(id) {
      return banjisaveInfo({ id: id }).then((res) => {
        let { formLabelAlign } = this;
        this.options = res.data.enable_list;
        if (id) {
          this.type_name = "修改";
          this.id = id;
          let {
            class_name,
            department_id,
            master_id,
            begin_time,
            end_time,
            mediator,
            counselor,
            teacherData,
            mediatorData,
            counselorData
          } = res.data.info;
          this.$refs.Bepartment.bepartment_List = department_id;
          formLabelAlign.department_id = department_id;
          formLabelAlign.class_name = class_name;
          formLabelAlign.begin_time = begin_time;
          formLabelAlign.end_time = end_time;
          formLabelAlign.mediator = mediator;
          formLabelAlign.counselor =counselor;
          this.timeData = [begin_time, end_time];
          this.teacherData = teacherData;
          this.mediatorData = mediatorData;
          this.counselorData = counselorData;
          //数据暂存，避免监听清除
          this.TSmaster_id = master_id;
        }
      });
    },
    getUserAndDept(query,par=null) {
        let params = {name:query}
        let {type} = par
        if (query !== '') {
          this.loading = true;
          getUserAndDepartment(params).then((res)=>{
            this.loading = false;
            if(type==1)
             this.teacherData = res.data
            else if(type==2)
              this.mediatorData =res.data;
            else if(type==3){
              // let ulist =res.data
              this.counselorData = res.data
            }
              // this.userList =res.data;
          })
        } 
        // else {
        //   this.userList = [];
        // }
      },
    on_timeData() {
      let { timeData, formLabelAlign } = this;
      if (timeData == null) {
        formLabelAlign.begin_time = "";
        formLabelAlign.end_time = "";
      } else {
        formLabelAlign.begin_time = timeData[0];
        formLabelAlign.end_time = timeData[1];
      }
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) {
            formLabelAlign.id = id;
          }
          banjisave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
  computed: {
    department: function () {
      return this.formLabelAlign.department_id;
    },
  },

  watch: {
    //监听部门修改班级
    department(id) {
      this.formLabelAlign.master_id = "";
      usergetUserByDepart({ department_id: id }).then((res) => {
        let { data } = res;
        this.teacher_Data = data;
        if (this.TSmaster_id) {
          this.formLabelAlign.master_id = this.TSmaster_id;
          this.TSmaster_id = "";
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>